<template>
  <b-card class="container py-1 min-h-[80vh]">
    <h3 class="font-bold text-[24px] text-[#222222] mb-1">
      Aplikasiku
    </h3>
    <p class="font-normal text-[#626262] text-[14px] mb-1">
      Integrasikan antar layanan di Komerce dengan 1 akunmu agar saling terhubung dan membuka banyak manfaat fitur yang powerful
    </p>
    <div
      v-if="profile !== null"
      class="mb-[32px]"
    >
      <b-row
        v-for="items in productList"
        :id="`popover-${items.id}`"
        :key="items.name"
        class="mb-1"
        :style="items.bgStyle"
      >
        <b-col
          cols="12"
          class="d-flex justify-content-between py-2 pl-4 pr-2 mx-0"
        >
          <a
            :href="items.deep_link"
            target="_blank"
          >
            <img
              :src="items.logo"
              :class="items.hImage"
            >
          </a>
          <button
            v-if="items.active === true"
            class="aktif"
            :class="items.name === 'pumkm' ? 'h-[28px]':'' || items.verified === false ? 'd-none' : ''"
            disabled
          >
            Terdaftar
          </button>
          <button
            v-if="items.learn_more === true || items.status === false"
            class="more"
            disabled
            :class="items.active === true ? 'd-none':''
              || items.name === 'pumkm' ? 'h-[28px]':''
            "
          >
            <a
              :href="items.deep_link"
              style="color: #08A0F7;"
              target="_blank"
            >
              Pelajari Selengkapnya
            </a>
          </button>
          <button
            v-if="items.verified === false && items.active === true"
            class="verif"
            :class="items.name === 'pumkm' ? 'h-[28px]':''"
            @click="resendVerif(items.name)"
          >
            Kirim Ulang Verifikasi
          </button>
        </b-col>
      </b-row>
      <b-popover
        v-if="productList[1].active === false"
        target="popover-2"
        placement="topleft"
        triggers="hover"
      >
        <b>Integrasikan Akunmu dengan Kompack biar lebih Hemat!</b> Nikmati layanan pergudangan yang mudah dan murah mulai dari Rp3.000 tanpa biaya sewa bulanan.
        <a
          href="https://kompack.id/user-komship"
          target="_blank"
          style="color: #F95031 !important; text-decoration: underline !important;"
        >Klik di sini</a>
      </b-popover>
    </div>
    <div v-if="isKomship">
      <div
        class="text-[#222222] text-[16px] font-[500] mb-[12px]"
      >
        Fitur Berlangganan
      </div>
      <div class="text-[#626262] text-[14px] font-[400]">
        Fitur premium yang akan menambah kemudahan dan menjadi nilai lebih toko online mu
      </div>
      <div class="mt-[12px]">
        <b-row class="border-y py-1">
          <b-col
            class="d-flex justify-between pl-4 pr-2 mx-0"
          >
            <div class="d-flex space-x-2 mr-auto">
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
                class="h-[24px]"
              >
              <div class="text-[#222222] text-[16px] font-[600]">
                Notifikasi Whatsap Premium
              </div>
            </div>
            <b-link
              v-if="defaultWA === 0"
              class="text-[#08A0F7]"
              @click="$router.push({ path: '/opsional-feature', query: { wa: true } })"
            >
              Pelajari selengkapnya
            </b-link>
            <div v-if="badge === 'Regular'">
              <b-button
                v-if="defaultWA === 1"
                variant="primary"
                size="sm"
                @click="$router.push({ path: '/opsional-feature', query: { wa: true } })"
              >
                Aktifkan Premium
              </b-button>
              <div
                v-if="defaultWA === 2 && expiredDay !== 0"
              >
                <b-button
                  variant="primary"
                  size="sm"
                  @click="handleRenewWA()"
                >
                  Perpanjang
                </b-button>
                <div class="text-[#222222] text-[16px] d-inline-flex ml-1">
                  Berakhir {{ expiredDay }} hari lagi
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>
<script>
import { mapState } from 'vuex'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { productList } from './config'

export default {
  data() {
    return {
      productList,
      isKomship: false,
      defaultWA: false,
      expiredDay: 0,
      badge: '',
      listProduct: [],
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
    ...mapState('whatsapp', ['check_wa', 'dashboard_wa']),
  },
  async mounted() {
    await this.fetchAplikasiku()
    await this.updateProduct()
    await this.$store.dispatch('whatsapp/getUpdateCheckWa')
    await this.$store.dispatch('whatsapp/getUpdateDashboardWa')
    await this.checkWA()
    this.$forceUpdate()
  },
  methods: {
    async updateProduct(productName, updateData) {
      const index = this.productList.findIndex(item => item.name === productName)
      if (index !== -1) {
        this.productList[index] = {
          ...this.productList[index],
          ...updateData,
        }
      }
    },
    async fetchAplikasiku() {
      try {
        this.$http_new.get('auth/api/v1/user/aplikasiku').then(response => {
          this.updateProduct('komship', response.data.data.is_komship)
          this.updateProduct('kompack', response.data.data.is_kompack)
          this.updateProduct('komtim', response.data.data.is_komtim)
          this.updateProduct('komcard', response.data.data.is_komcard)
          this.updateProduct('komform', response.data.data.is_komform)
          this.updateProduct('komplace', response.data.data.is_komplace)
          this.updateProduct('komchat', response.data.data.is_komchat)
          this.updateProduct('komclass', response.data.data.is_komclass)
          this.updateProduct('pumkm', response.data.data.is_pumkm)

          if (response.data.data.is_komship.verified === true && response.data.data.is_komship.active === true) {
            this.isKomship = true
          }
        })
        this.loading = false
        this.$forceUpdate()
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
        this.loading = false
      }
    },
    async checkWA() {
      this.defaultWA = this.check_wa.is_notification_whatsapp
      this.expiredDay = this.dashboard_wa !== null ? this.dashboard_wa.whatsapp_expired_at : 0
      this.badge = this.check_wa.is_user_type
      if (this.expiredDay === 0) {
        this.defaultWA = 0
      }
    },
    handleRenewWA() {
      if (this.expiredDay > 0) {
        this.$router.push({ name: 'dashboard-whatsapp', params: { modal: 'renew' } })
      } else {
        this.$router.push({ name: 'opsional-feature', params: { modal: 'renew' } })
      }
    },
    resendVerif(productName) {
      const params = {
        email: this.profile.user_email,
        product_name: productName,
      }
      this.$http_new.post('auth/api/v1/auth/resend-verification', params).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sukses',
            icon: 'CheckIcon',
            text: 'Kirim Ulang Verifikasi Berhasil',
            variant: 'success',
          },
        }, 2000)
        this.loading = false
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: err,
            variant: 'danger',
          },
        }, 2000)
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped src="./status.scss">
</style>
