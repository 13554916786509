/* eslint-disable import/prefer-default-export */
export const productList = [
  {
    name: 'komship',
    logo: 'https://storage.googleapis.com/komerce/assets/svg/komship-logo-yellow.svg',
    bgStyle: 'box-shadow: 2px 4px 18px rgba(251, 188, 5, 0.12)',
    hImage: 'h-[28px]',
    id: 1,
  },
  {
    name: 'kompack',
    logo: 'https://storage.googleapis.com/komerce/assets/logo/Kompack-logo.svg',
    bgStyle: 'box-shadow: 2px 4px 18px rgba(66, 133, 244, 0.12)',
    hImage: 'h-[28px]',
    id: 2,
  },
  {
    name: 'komtim',
    logo: 'https://storage.googleapis.com/komerce/assets/logo/Komtim-logo.svg',
    bgStyle: 'box-shadow: 2px 4px 18px rgba(52, 168, 83, 0.12)',
    hImage: 'h-[30px]',
    id: 3,
  },
  {
    name: 'komcard',
    logo: 'https://storage.googleapis.com/komerce/assets/svg/Komcards-logo.svg',
    bgStyle: 'box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.12)',
    hImage: 'h-[25px]',
    id: 4,
  },
  {
    name: 'komform',
    logo: 'https://storage.googleapis.com/komerce/assets/logo/logo-text/logo-komformm.svg',
    bgStyle: 'box-shadow: 0px 4px 18px rgba(161, 99, 99, 0.12)',
    hImage: 'h-[28px]',
    id: 5,
  },
  {
    name: 'komplace',
    logo: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/Komplace.png',
    bgStyle: 'box-shadow: 0px 4px 18px rgba(255, 48, 48, 0.12)',
    hImage: 'h-[28px]',
    id: 6,
  },
  {
    name: 'komchat',
    logo: 'https://storage.googleapis.com/komerce/assets/logo/komchat-logo.svg',
    bgStyle: 'box-shadow: 0px 4px 18px rgba(255, 48, 48, 0.12)',
    hImage: 'h-[25px]',
    id: 7,
  },
  {
    name: 'komclass',
    logo: 'https://storage.googleapis.com/komerce/assets/LP-Komclass/boootcamp/logo/komclass.png',
    bgStyle: 'box-shadow: 0px 4px 18px rgba(166, 48, 255, 0.12)',
    hImage: 'h-[28px]',
    id: 8,
  },
  {
    name: 'pumkm',
    logo: 'https://storage.googleapis.com/komerce/assets/logo/logo-text/PUMKM-Logo.svg',
    bgStyle: 'box-shadow: 2px 4px 18px rgba(251, 188, 5, 0.12)',
    hImage: 'h-[45px]',
    id: 9,
  },
]
